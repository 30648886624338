import React from "react";
import Layout from "../../../components/layout";
import SEO from "../../../components/seo";
import CategoryTemplate from "../../../templates/category-page";

// https://coinmarketcap.com/nft/upcoming/
const coins = [
  "axie-infinity",
  "decentraland",
  "the-sandbox",
  "theta-token",
  "flow",
  "apecoin",
  "gala",
  "enjincoin",
  "chiliz",
  "stepn",
  "ecomi",
  "smooth-love-potion",
  "wax",
  "illuvium",
  "mobox",
  "origin-protocol",
  "superrare",
  "handshake",
  "rarible",
];
const title = "NFT (Non-fungible token)";
const description = `Theoretisch kann jeder Vermögenswert digitalisiert und zum NFT werden: Zeichnungen, digitale Kunstwerke, Videoclips oder
auch echte Besitztümer. Wichtig ist dabei, dass die NFTs Informationen beinhalten, die ihre Einzigartigkeit belegen. So
lässt sich der jeweilige Besitzer stets zurückverfolgen und kann seinen Anspruch geltend machen. Nun stellt sich natürlich
die Frage, wie man einen digitalen Vermögenswert schützt? Schließlich kann man eine Kopie eines jeden digitalen Kunstwerks
durch einen einfachen Rechtsklick mit der Maus auf seinem eigenen PC speichern.`

const Page: React.FC = () => {
  return (
    <Layout>
      <SEO keywords={["nfts"]} title={title} description={description} />
      <CategoryTemplate title={title} coins={coins} description={description} coingeckoId="non-fungible-tokens-nft" />
    </Layout>
  );
};

export default Page;
